import React from "react";
import Container from "../../../components/container";
import { navigate } from "gatsby";
import OneQuestionForm from "../../../components/OneQuestionForm";

export default function Index() {
  return (
    <Container>
      <section className="flex-col flex items-center md:justify-between mt-16 mb-16 md:mb-12">
        <h1 className="text-6xl font-bold tracking-tighter leading-tight md:pr-8">
          Taza Socorrismo.
        </h1>
        <h4 className="text-center md:text-left text-lg mt-5">
          Seguro que ya habéis descubierto el código de la taza.
        </h4>
        <img
          className="mt-5 rounded-2xl "
          src="/taza.jpg"
          title="Socorrismo"
          alt="Socorrismo"
        />
        <OneQuestionForm
          question="¿Cuál es?"
          validateResponse={(input) => {
            let normalizedInput = input
              .normalize("NFKD")
              .replace(/[\u0300-\u036f]/g, "");
            return (
              normalizedInput.toLowerCase().includes("frigsa 2004") &&
              normalizedInput.toLowerCase().includes("2004")
            );
          }}
          onSuccess={() => navigate("/taza2/fin")}
        />
      </section>
      {/*<button*/}
      {/*  onClick={() => navigate("/taza2/compatibilidad")}*/}
      {/*  type="button"*/}
      {/*  className=" my-6 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"*/}
      {/*>*/}
      {/*  Probar suerte*/}
      {/*</button>*/}
      {/*<RadioButtons question={questions[currentQuestion]} />*/}
      {/*<OneQuestionForm*/}
      {/*  validateResponse={validateResponse}*/}
      {/*  question="¿Qué prefiere Patricia, playa o montaña?"*/}
      {/*/>*/}
    </Container>
  );
}
